import React from "react";
import "twin.macro";
import { Link } from "gatsby";
import styled from "@emotion/styled";

const StyledTeamPerson = styled.div`
  &:nth-of-type(even) {
    .decor1 {
      bottom: 0;
      left: 0;
      top: 0.75rem;
      right: 0.75rem;
    }

    .decor2 {
      bottom: 0.75rem;
      left: 0.75rem;
      top: 0;
      right: 0;
    }
  }
`;

const TeamPersonDesc = ({ name, surname, desc, link }) => {
  return (
    <StyledTeamPerson tw="relative even:text-right mb-10">
      <div
        className="decor1"
        tw="w-full h-full absolute border-2 border-two bottom-3 left-3"
      ></div>
      <div
        className="decor2"
        tw="w-full h-full absolute bg-four-100 bg-opacity-10 top-3 right-3"
      ></div>
      <div tw="relative p-10 z-10">
        <h1 tw="pb-4 text-2xl font-medium">
          {name} {surname}
        </h1>
        <div tw="text-xl pb-4">{desc}</div>
        <Link tw="text-four" to={link}>
          Zobacz więcej...
        </Link>
      </div>
    </StyledTeamPerson>
  );
};

export default TeamPersonDesc;
