import React from "react";
import Layout, { PageWrapper } from "../components/layout";
import { PageMain, PageSection } from "../styles/index/_components";
import TeamPersonDesc from "../components/blocks/teamPersonDesc";

const Zespol = () => {
  return (
    <Layout>
      <PageMain>
        <PageSection>
          <PageWrapper>
            <TeamPersonDesc
              name={"Maciej"}
              surname={"Schmidt"}
              link={"/zespol/maciej"}
              desc={`Jestem dyplomowanym psychologiem oraz seksuologiem,
          który ukończył studia psychologiczne oraz psychoseksuologiczne na Uniwersytecie Humanistycznospołecznym SWPS w Sopocie
          . Obecnie pełnię także rolę wykładowcy na wydziale psychologii
          klinicznej tego samego uniwersytetu oraz wspomagam funkcjonowanie Koła
          Naukowego Seksuologii Klinicznej, z którym to organizuję wspólnie
          różne wydarzenia, między innymi międzynarodowe konferencje naukowe
          dotyczące seksualności człowieka.`}
            />
            <TeamPersonDesc
              name={"Justyna"}
              surname={"Barton"}
              link={"/zespol/justyna"}
              desc={`Jestem psycholożką, psychoseksuolożką oraz
          psychotraumatolożką w trakcie certyfikacji, przyjmującą w Gdańsku.
          Ukończyłam pięcioletnie psychologiczne studia magisterskie oraz studia
          pierwszego stopnia na kierunku psychoseksuologia, na Uniwersytecie Humanistycznospołecznym SWPS w Sopocie`}
            />
            <TeamPersonDesc
              name={"Julianna"}
              surname={"Graczyk"}
              link={"/zespol/julianna"}
              desc={`Jestem absolwentką Uniwersytetu SWPS na kierunku Psychoseksuologia oraz Psychologia Kliniczna. Aktywnie działam w Stowarzyszeniu Artystyczno-Społecznym Feeria, w którym obejmuje rolę psycholożki, piszę projekty i udzielam się artystycznie.`}
            />
          </PageWrapper>
        </PageSection>
      </PageMain>
    </Layout>
  );
};

export default Zespol;
